exports.components = {
  "component---src-pages-403-js": () => import("./../../../src/pages/403.js" /* webpackChunkName: "component---src-pages-403-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-adservio-js": () => import("./../../../src/pages/adservio.js" /* webpackChunkName: "component---src-pages-adservio-js" */),
  "component---src-pages-autentificare-js": () => import("./../../../src/pages/autentificare.js" /* webpackChunkName: "component---src-pages-autentificare-js" */),
  "component---src-pages-confirmarea-resetarii-parolei-js": () => import("./../../../src/pages/confirmarea-resetarii-parolei.js" /* webpackChunkName: "component---src-pages-confirmarea-resetarii-parolei-js" */),
  "component---src-pages-confirmarea-studentului-js": () => import("./../../../src/pages/confirmarea-studentului.js" /* webpackChunkName: "component---src-pages-confirmarea-studentului-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-login-js": () => import("./../../../src/pages/en/login.js" /* webpackChunkName: "component---src-pages-en-login-js" */),
  "component---src-pages-en-teacher-[slug]-js": () => import("./../../../src/pages/en/teacher/[slug].js" /* webpackChunkName: "component---src-pages-en-teacher-[slug]-js" */),
  "component---src-pages-en-teachers-js": () => import("./../../../src/pages/en/teachers.js" /* webpackChunkName: "component---src-pages-en-teachers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lesson-link-js": () => import("./../../../src/pages/lesson-link.js" /* webpackChunkName: "component---src-pages-lesson-link-js" */),
  "component---src-pages-link-lectiei-js": () => import("./../../../src/pages/link-lectiei.js" /* webpackChunkName: "component---src-pages-link-lectiei-js" */),
  "component---src-pages-mokinio-patvirtinimas-js": () => import("./../../../src/pages/mokinio-patvirtinimas.js" /* webpackChunkName: "component---src-pages-mokinio-patvirtinimas-js" */),
  "component---src-pages-mokytojai-korepetitoriai-papildomas-mokymasis-js": () => import("./../../../src/pages/mokytojai-korepetitoriai-papildomas-mokymasis.js" /* webpackChunkName: "component---src-pages-mokytojai-korepetitoriai-papildomas-mokymasis-js" */),
  "component---src-pages-mokytojas-[slug]-js": () => import("./../../../src/pages/mokytojas/[slug].js" /* webpackChunkName: "component---src-pages-mokytojas-[slug]-js" */),
  "component---src-pages-pakeisti-slaptazodi-js": () => import("./../../../src/pages/pakeisti-slaptazodi.js" /* webpackChunkName: "component---src-pages-pakeisti-slaptazodi-js" */),
  "component---src-pages-pamokos-nuoroda-js": () => import("./../../../src/pages/pamokos-nuoroda.js" /* webpackChunkName: "component---src-pages-pamokos-nuoroda-js" */),
  "component---src-pages-password-reset-confirmation-js": () => import("./../../../src/pages/password-reset-confirmation.js" /* webpackChunkName: "component---src-pages-password-reset-confirmation-js" */),
  "component---src-pages-password-reset-js": () => import("./../../../src/pages/password-reset.js" /* webpackChunkName: "component---src-pages-password-reset-js" */),
  "component---src-pages-patvirtinti-elpasta-js": () => import("./../../../src/pages/patvirtinti-elpasta.js" /* webpackChunkName: "component---src-pages-patvirtinti-elpasta-js" */),
  "component---src-pages-patvirtinti-slaptazodzio-pakeitima-js": () => import("./../../../src/pages/patvirtinti-slaptazodzio-pakeitima.js" /* webpackChunkName: "component---src-pages-patvirtinti-slaptazodzio-pakeitima-js" */),
  "component---src-pages-prisijungti-js": () => import("./../../../src/pages/prisijungti.js" /* webpackChunkName: "component---src-pages-prisijungti-js" */),
  "component---src-pages-profesori-[slug]-js": () => import("./../../../src/pages/profesori/[slug].js" /* webpackChunkName: "component---src-pages-profesori-[slug]-js" */),
  "component---src-pages-profesori-js": () => import("./../../../src/pages/profesori.js" /* webpackChunkName: "component---src-pages-profesori-js" */),
  "component---src-pages-reseteaza-parola-js": () => import("./../../../src/pages/reseteaza-parola.js" /* webpackChunkName: "component---src-pages-reseteaza-parola-js" */),
  "component---src-pages-student-confirmation-js": () => import("./../../../src/pages/student-confirmation.js" /* webpackChunkName: "component---src-pages-student-confirmation-js" */),
  "component---src-pages-verificati-email-js": () => import("./../../../src/pages/verificati-email.js" /* webpackChunkName: "component---src-pages-verificati-email-js" */),
  "component---src-pages-verify-email-js": () => import("./../../../src/pages/verify-email.js" /* webpackChunkName: "component---src-pages-verify-email-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blogPage.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-contacts-page-js": () => import("./../../../src/templates/contactsPage.js" /* webpackChunkName: "component---src-templates-contacts-page-js" */),
  "component---src-templates-for-teachers-js": () => import("./../../../src/templates/forTeachers.js" /* webpackChunkName: "component---src-templates-for-teachers-js" */),
  "component---src-templates-material-category-js": () => import("./../../../src/templates/materialCategory.js" /* webpackChunkName: "component---src-templates-material-category-js" */),
  "component---src-templates-material-js": () => import("./../../../src/templates/material.js" /* webpackChunkName: "component---src-templates-material-js" */),
  "component---src-templates-material-search-js": () => import("./../../../src/templates/materialSearch.js" /* webpackChunkName: "component---src-templates-material-search-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-registration-js": () => import("./../../../src/templates/registration.js" /* webpackChunkName: "component---src-templates-registration-js" */),
  "component---src-templates-single-blog-page-js": () => import("./../../../src/templates/singleBlogPage.js" /* webpackChunkName: "component---src-templates-single-blog-page-js" */),
  "component---src-templates-subject-search-js": () => import("./../../../src/templates/subjectSearch.js" /* webpackChunkName: "component---src-templates-subject-search-js" */),
  "component---src-templates-subjects-js": () => import("./../../../src/templates/subjects.js" /* webpackChunkName: "component---src-templates-subjects-js" */),
  "component---src-templates-subjects-list-js": () => import("./../../../src/templates/subjectsList.js" /* webpackChunkName: "component---src-templates-subjects-list-js" */)
}

