import { createMyCache, myCache } from './create-emotion-cache';

import { CacheProvider } from '@emotion/react';

import * as React from 'react';
import { ShouldUpdateScrollArgs } from 'gatsby';
import type { GatsbyBrowser } from 'gatsby';
import { tr } from 'date-fns/locale';
// import Layout from '@src/components/layout/layout';
// import pageSlugs from '@src/components/layout/pageSlugs';
// import { useTranslation } from 'react-i18next';
// import { filterLocaleNodesSubjects } from '@src/i18n/localeList';
// import api from '@src/utils/api';

// shouldUpdateScroll override
// export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
//   element,
//   props,
// }) => {
//   const locale = React.useRef(process.env.GATSBY_DEFAULT_LANGUAGE || 'lt');
//   const [subjectsViewModel, setSubjectsViewModel] = React.useState([]);

//   const { i18n } = useTranslation();

//   const otherSlugs = Object.keys(pageSlugs.homePage).map((key) => {
//     return {
//       locale: key,
//       slug: pageSlugs.homePage[key],
//     };
//   });

//   const tldMap = {
//     en: '.com',
//     lt: '.lt',
//     ro: '.ro',
//   };
//   //   TODO: check this
//   const subjectsData = filterLocaleNodesSubjects(
//     props.data.allContentfulMokomasisDalykas.nodes,
//     locale.current
//   );

//   React.useEffect(() => {
//     if (i18n.language !== locale.current) {
//       i18n.changeLanguage(locale.current);
//     }

//     //   if (typeof window !== `undefined`) {
//     const domain = window.location.origin;

//     const domainTldLocale = Object.keys(tldMap).find((key) =>
//       domain.includes(tldMap[key])
//     );

//     if (domainTldLocale) {
//       locale.current = domainTldLocale;
//     }
//     const controller = new AbortController();

//     const load = async () => {
//       try {
//         const countsResponse = await api.get('teachers/counts', {
//           controller,
//         });
//         const updatedSubjectsViewModel = subjectsData.map((subject) => {
//           const subjectRes =
//             countsResponse.find(
//               (apiRes) => subject.uniqueId === apiRes.subject
//             ) || 0;
//           return {
//             name: subject.name,
//             slug: subject.slug,
//             icon: subject.navIcon,
//             image: subject.icon,
//             id: subject.uniqueId,
//             count: subjectRes?.count | 0,
//           };
//         });
//         setSubjectsViewModel(updatedSubjectsViewModel);
//       } catch (error) {
//         console.log(error);
//       }
//     };
//     load();

//     return () => controller?.abort();
//   }, []);
//   return (
//     <Layout
//       locale={locale.current}
//       otherSlugs={otherSlugs}
//       subjectsViewModel={subjectsViewModel}
//       subjectsData={subjectsData}
//       currentSlug={pageSlugs.homePage[locale.current]}
//     >
//       {React.cloneElement(element, {
//         locale: locale.current,
//         subjectsWithCounts: subjectsViewModel,
//       })}
//     </Layout>
//   );
// };
export const onInitialClientRender: GatsbyBrowser['onInitialClientRender'] =
  () => {
    const GTM_HEAD_SCRIPT = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-THKCK6X');
`;
    // wait to init GTM until after React has hydrated in this lifecycle
    eval(GTM_HEAD_SCRIPT);
  };

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}): JSX.Element => <CacheProvider value={myCache}>{element}</CacheProvider>;

export function shouldUpdateScroll({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition,
}: ShouldUpdateScrollArgs) {
  const {
    location: { hash, pathname, search },
  } = routerProps;
  // const currentPosition = getSavedScrollPosition(location);
  const params = new URLSearchParams(search);
  if (params.has('page')) {
    window.scrollTo([0, document.documentElement.scrollTop]);
    return false;
  } else {
    window.scrollTo([0, 0])
    return true;
  }

  // if (prevRouterProps) {
  //   const {
  //     location: { pathname: oldPathname, search: oldSearch },
  //   } = prevRouterProps;
  //   const params = new URLSearchParams(search);

  //   if (oldPathname === pathname) {
  //     if (hash) {
  //       return decodeURI(hash.slice(1));
  //     }

  //     if (search.length > 1 && oldSearch !== search) {
  //       if (params.has('page') && params.get('page') === '1') {
  //         return true;
  //       } else {
  //         return true;
  //       }
  //     } else {
  //       return true;
  //     }
  //   }
  // }

  // return true;
}
// export const onRenderBody: GatsbyBrowser['onRenderBody'] = ({
//     setHtmlAttributes,
//     setHeadComponents,
//   }) => {
//     setHtmlAttributes(HtmlAttributes);
//     setHeadComponents(HeadComponents);
//   };
