// example theme file
const theme = {
  colors: {
    text: '#242424',
    text400: '#414458',
    text700: '#7E7E7E',
    background: '#fff',
    primary: '#5388FF',
    primaryDark: '#4552B0',
    primaryPale: '#D4E1FF',
    primaryPaleBg: '#EDF3FF',
    secondary: '#0896B3',
    greenPale: '#D2F2DF',
    muted: '#f6f6f6',
    violet: '#5B6DE8',
    grey800: '#808080',
    grey600: '#666666',
    grey200: '#E7E7E7',
    grey100: '#F6F6F8',
    white: '#fff',
    black: '#000',
    label: '#ACACAC',
    textLight: '#fff',
    error: '#D60F0F',
    rating: '#94E0B4',
    ratingInactive: '#E0E0E0',
    primaryDarkGradient:
      'linear-gradient(307.47deg, #5388FF -3.94%, #D4E1FF 180.7%)',
    secondaryDarkGradient:
      'linear-gradient(291.67deg, #53BDE0 -8.02%, #2A5F70 216%)',
    fieldBorder: '#ECECEC',
    modes: {
      dark: {
        primary: '#5B6DE8',
        primaryDarkGradient:
          'linear-gradient(307.47deg, #5B6DE8 -3.94%, #2E3774 180.7%)',
        secondaryDarkGradient:
          'linear-gradient(291.67deg, #53BDE0 -8.02%, #2A5F70 216%)',
      },
    },
  },
  sizes: {
    container: '1528px',
  },
  fontSizes: [10, 12, 16, 20, 24, 32, 44, 48, 56],
  fonts: {
    body: 'Roboto, sans-serif',
    heading: 'inherit',
    monospace: 'monospace',
  },
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.6,
    heading: 1.6,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
    },
    a: {
      color: 'primary',
    },
    h1: {
      variant: 'text.heading',
      fontSize: [6, 7, 8],
    },
    h2: {
      variant: 'text.heading',
      fontSize: [5, 6],
    },
    h3: {
      variant: 'text.heading',
      fontSize: [4, 5],
    },
    h4: {
      variant: 'text.heading',
      fontSize: 4,
    },
    h5: {
      variant: 'text.heading',
      fontSize: 3,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 2,
    },
  },
  buttons: {
    primary: {
      color: 'background',
      bg: 'primary',
      fontSize: 3,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      borderRadius: '10px',
      transition:
        'background-color .2s linear, border-color .2s linear, color .2s linear',
      padding: '0.6em 36px',
      '&:hover': {
        bg: 'primaryPale',
        color: 'primary',
      },
      '&:disabled': {
        opacity: '0.6',
        cursor: 'not-allowed',
        '&:hover': {
          color: 'background',
          bg: 'primary',
        },
      },
    },
    primarySmall: {
      color: 'background',
      bg: 'primary',
      fontSize: 3,
      borderWidth: '2px',
      borderStyle: 'solid',
      borderColor: 'primary',
      borderRadius: '10px',
      transition:
        'background-color .2s linear, border-color .2s linear, color .2s linear',
      padding: '12px 28px',
      '&:hover': {
        bg: 'primaryPale',
        color: 'primary',
      },
    },
    inverted: {
      color: 'primary',
      bg: 'transparent',
      fontSize: 3,
      borderWidth: '2px',
      borderColor: 'primaryPale',
      borderStyle: 'solid',
      borderRadius: '10px',
      transition:
        'background-color .2s linear, border-color .2s linear, color .2s linear',
      padding: '12px 36px',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        borderColor: 'primary',
      },
      '&:disabled': {
        cursor: 'not-allowed',
        color: 'primary',
        bg: 'transparent',
        borderColor: 'primaryPale',
      },
    },
    invertedSmall: {
      color: 'primary',
      bg: 'transparent',
      fontSize: 3,
      borderWidth: '2px',
      borderColor: 'primaryPale',
      borderStyle: 'solid',
      borderRadius: '10px',
      transition:
        'background-color .2s linear, border-color .2s linear, color .2s linear',
      padding: '12px 28px',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        borderColor: 'primary',
      },
    },

    invertedSmallWhite:  {
      color: 'primary',
      bg: 'white',
      fontSize: 3,
      borderWidth: '2px',
      borderColor: 'primaryPale',
      borderStyle: 'solid',
      borderRadius: '10px',
      transition:
        'background-color .2s linear, border-color .2s linear, color .2s linear',
      padding: '12px 28px',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        borderColor: 'primary',
      },
    },
    featuredWhiteButton: {
      color: 'primary',
      bg: 'white',
      fontSize: 3,
      borderWidth: 0,
      borderRadius: '10px',
      transition:
        'background-color .2s linear, border-color .2s linear, color .2s linear',
      padding: '12px 28px',
      '&:hover': {
        bg: 'primary',
        color: 'background',
        borderColor: 'primary',
      },
    },
    secondary: {
      color: 'background',
      bg: 'secondary',
      transition: 'background 0.2s linear',
      minWidth: '215px',
      padding: '11px',
      fontWeight: '700',
      lineHeight: '29px',
      borderWidth: '1px',
      borderColor: 'secondary',
      borderStyle: 'solid',
      '&:hover': {
        bg: 'text',
      },
      '&:disabled': {
        cursor: 'not-allowed',
      },
    },
    light: {
      color: 'primary',
      bg: 'white',
      minWidth: '215px',
      padding: '11px',
      fontWeight: '700',
      lineHeight: '29px',
      borderWidth: '1px',
      borderColor: 'white',
      borderStyle: 'solid',
    },
    secondaryLight: {
      color: 'secondary',
      bg: 'white',
      minWidth: '215px',
      padding: '11px',
      fontWeight: '700',
      lineHeight: '29px',
      borderWidth: '1px',
      borderColor: 'white',
      borderStyle: 'solid',
    },
    grey: {
      color: 'black',
      bg: 'grey200',
      minWidth: '215px',
      padding: '11px',
      fontWeight: '700',
      lineHeight: '29px',
      borderWidth: '1px',
      borderColor: 'grey200',
      borderStyle: 'solid',
    },
    empty: {
      bg: 'transparent',
      padding: 0,
      display: 'flex',
      alignItems: 'center',
    },
    tabActive: {
      bg: 'transparent',
      color: 'primaryDark',
      borderBottom: '6px solid',
      borderColor: 'primaryDark',
      borderRadius: 0,
    },
    tabDefault: {
      bg: 'transparent',
      color: 'text400',
      borderBottom: '6px solid',
      borderColor: '#D7DBF4',
      borderRadius: 0,
    },
  },
  cards: {
    primary: {
      borderRadius: '20px',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      background: 'white',
    },
    dark: {
      color: '#fff',
      borderRadius: '20px',
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
      background: 'linear-gradient(106.85deg, #5388FF 4.03%, #272E62 263.26%)',
    },
  },
  images: {
    avatar: {
      width: '100%',
      height: '100%',
      borderRadius: 99999,
      objectFit: 'cover',
    },
  },
  badges: {
    primary: {
      color: 'red',
      fontSize: 2,
      color: 'text',
      fontWeight: 500,
      backgroundColor: 'white',
      borderColor: 'primaryPale',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius: '5px',
      marginRight: '12px',
      padding: '3px 12px',
    },
  },
  forms: {
    label: {
      color: 'label',
    },
    input: {
      borderWidth: '2px',
      borderColor: 'fieldBorder',
      color: 'black',
      padding: '15px',
      '&:focus': {
        borderColor: 'primary',
        outline: 'none',
      },
    },
    select: {
      borderWidth: '2px',
      borderColor: 'fieldBorder',
      color: 'black',
      padding: '15px',
      '&:focus': {
        borderColor: 'primary',
        outline: 'none',
      },
    },
  },
};

export default theme;
