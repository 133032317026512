import createCache, { EmotionCache } from '@emotion/cache'

export const createMyCache = (): EmotionCache =>
  createCache({
    key: 'css',
    // prepend: true,
    stylisPlugins: [
      /* your plugins here */
    ],
  })

export const myCache: EmotionCache = createMyCache()
